import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import ImagePopup from "../ImagePopup"

export default props => {
  const { news, defaultImage } = props
  const { header, subheader, webcontent, images, slug } = news

  const [selectedImage, setSelectedImage] = useState(null)
  const [popupOpen, setPopupOpen] = useState(false)

  const maxLength = 700
  const sneakPeak =
    webcontent.length > maxLength ? webcontent.substring(0, maxLength) + "..." : webcontent
  const firstImage = images && images.length && images[0]

  return (
    <div className="detail">
      <div className="detail-left">
        {firstImage ? (
          <div
            className="detail-image-popup"
            role="button"
            tabIndex={0}
            onClick={() => {
              setSelectedImage(0)
              setPopupOpen(true)
            }}
            onKeyPress={() => {
              setSelectedImage(0)
              setPopupOpen(true)
            }}
          >
            <Img fluid={firstImage.image.childImageSharp.fluid} />
          </div>
        ) : (
          <Img fluid={defaultImage.childImageSharp.fluid} />
        )}
      </div>
      <ImagePopup
        open={popupOpen}
        setOpen={setPopupOpen}
        images={images}
        defaultId={selectedImage}
      />
      <div className="detail-right">
        <h2>{header}</h2>
        <h3>{subheader}</h3>
        <div dangerouslySetInnerHTML={{ __html: sneakPeak }} />
        <Link to={`/news/${slug}`}>Weitere Informationen...</Link>
      </div>
    </div>
  )
}
