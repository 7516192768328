import React, { useState } from "react"
import { graphql } from "gatsby"

import PageNavigation from "../components/PageNavigation"
import CardNews from "../components/cards/CardNews"

export default ({ data }) => {
  const { younion, younionLogo } = data
  const { newsItems } = younion
  const [page, setPage] = useState(1)

  const newsPerPage = 5

  return (
    <div>
      <h1>News</h1>
      <PageNavigation
        items={newsItems}
        itemsPerPage={newsPerPage}
        page={page}
        setPage={setPage}
      />
      <div className="detail-items">
        {newsItems
          .slice(
            (page - 1) * newsPerPage,
            (page - 1) * newsPerPage + newsPerPage
          )
          .map(news => (
            <CardNews key={news._id} news={news} defaultImage={younionLogo} />
          ))}
      </div>
      <PageNavigation
        items={newsItems}
        itemsPerPage={newsPerPage}
        page={page}
        setPage={setPage}
      />
    </div>
  )
}

export const query = graphql`
  query {
    younion {
      newsItems {
        ...newsItemFields
      }
    }

    younionLogo: file(relativePath: { eq: "younion_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
