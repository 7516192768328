import React from "react"

import "./PageNavigation.css"

export default props => {
  const { items, itemsPerPage = 5, page, setPage } = props

  const pageRange = 2 // from -2 to +2 example: current page = 3 -> 1 2 3 4 5
  const maxPages = Math.ceil(items.length / itemsPerPage)

  // Returns a Array of max 5 elements to Navigate the pages
  const getFirstPagesPoint = () => {
    const pageList = []
    for (let i = -pageRange; i <= pageRange; i++) {
      const pageElement = page + i
      if (pageElement > 0 && pageElement < maxPages + 1) {
        pageList.push(pageElement)
      }
    }
    return pageList
  }

  const updatePage = page => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
    setPage(page)
  }

  const goPreview = () => page - 1 !== 0 && updatePage(page - 1)
  const goNext = () => page !== maxPages && updatePage(page + 1)

  return (
    <div className="page-navigation">
      <span
        role="button"
        tabIndex={0}
        onClick={goPreview}
        onKeyPress={goPreview}
      >
        {page - 1 !== 0 ? "<" : null}
      </span>
      {getFirstPagesPoint().map(pageElement =>
        pageElement === page ? (
          <span key={pageElement} style={{ color: "red" }}>
            {pageElement}
          </span>
        ) : (
          <span
            key={pageElement}
            role="button"
            tabIndex={0}
            onClick={() => updatePage(pageElement)}
            onKeyPress={() => updatePage(pageElement)}
          >
            {pageElement}
          </span>
        )
      )}
      <span role="button" tabIndex={0} onClick={goNext} onKeyPress={goNext}>
        {page !== maxPages ? ">" : null}
      </span>
    </div>
  )
}
